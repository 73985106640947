<template>
  &lt; back
  <div style="max-width: 600px; margin: 100px auto 150px;">
    <h1 style="margin: 12px 0 36px;">Leaderboard</h1>
    <div v-if="leaderboard.length === 0" class="flash">
      Loading...
    </div>
    <div v-else>
      <table>
        <tr id="lbhead">
          <th></th>
          <th>Name</th>
          <th>Manpower</th>
          <th>Fights</th>
          <th>Wins</th>
        </tr>
        <tbody>
        <tr v-for="(man, i) in leaderboard" :key="man[5]" style="cursor:pointer;" @click="$router.push(`/man/${man[5]}`)">
          <td style="padding-left: 10px;">{{i+1}}. </td>
          <td>{{man[4] ? man[4] : "A Real Man"}} (#{{man[5]}})<span v-if="man[3]"><br><small>Owner: {{man[3]}}</small></span></td>
          <td style="text-align: center">{{man[0]}}</td>
          <td style="text-align: center">{{man[2]}}</td>
          <td style="text-align: center">{{man[1]}} ({{ man[2] > 0 ? (((man[1]/man[2]))*100.0).toFixed(1) : "0.0" }}%)</td>
        </tr>
        </tbody>
      </table>
      <button type="button" class="btn btn-lb" @click="getLeaders">Load more...</button>
    </div>
  </div>
</template>

<script>
import faunadb from 'faunadb';
const q = faunadb.query

export default {
  name: "Leaderboard",
  props: ['db'],
  data(){
    return {
      leaderboard: [],
      after: null
    }
  },
  methods:{
    getLeaders(){
      const self = this
      let opts = { size: 25 }
      if(self.after){
        opts["after"] = self.after
      }
      self.db.query(
        q.Paginate(
            q.Difference(
                q.Match(q.Index("leaderboard")),
                q.Match(q.Index("leaderboard_fights"), 0)
            ),
            opts
        )
      )
      .then((ret) => {
        self.leaderboard = self.leaderboard.concat(ret.data)
        self.after = ret.after
      })
      .catch(async (err) => console.error(err))
    }
  },
  mounted(){
    this.getLeaders()
  }
}
</script>

<style scoped>
table{
  border-collapse: collapse;
  width: 100%;
}

#lbhead{
  background: var(--man2);
}

#lbhead th{
  padding: 12px 2px;
}

td{
  padding: 6px 2px;
}

tbody tr:nth-child(even) {background: var(--black)}
tbody tr:nth-child(odd) {background: #3d3d3d;}

.btn-lb{
  width: 100%;
  margin-top: 24px;
}
</style>